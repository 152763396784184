import React, {useEffect} from 'react';
import debounce from 'lodash/debounce';
import Downshift from 'downshift';
import {gql, useLazyQuery} from "@apollo/client";
import {Grid, Paper, MenuItem, TextField} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const SEARCH_QUERY = (category, extraAttributes = [], subType) => {
    if (category === 'product') {
        return gql`
        query findProduct($search: String!, $type: String!, $subType: String) {
            findProduct (search: $search, type: $type, subType: $subType) { 
                id code description ${extraAttributes.map(attr => attr).join(' ')}
            }
        }
        `
    } else {
        return gql`
        query findLabour($search: String!, $type: String!) {
            findLabour (search: $search, type: $type) { 
                id code description
            }
        }
        `
    }
};

const useStyles = makeStyles((theme) => ({
    paperA:{
        padding:'16px',
    },
    container: {
        position: 'relative',
      },
    
}));

function renderInput(inputProps) {
    const { InputProps, classes, ref, ...other } = inputProps;
    return (
      <TextField
        variant="outlined"
        InputProps={{
          inputRef: ref,
          classes: {
            root: classes.inputRoot,
            input: classes.inputInput,
          },
          ...InputProps,
        }}
        {...other}
      />
    );
}
  
function renderSuggestion({ suggestion, index, itemProps, highlightedIndex, selectedItem }) {
    const isHighlighted = highlightedIndex === index;
    const isSelected = (selectedItem || '').indexOf(suggestion.code) > -1;
  
    return (
      <MenuItem
        {...itemProps}
        key={suggestion.code}
        selected={isHighlighted}
        component="div"
        style={{
          fontWeight: isSelected ? 500 : 400,
        }}
      >
       <span style={{fontWeight: 500, marginRight: 8}}>{suggestion.code}:</span>{suggestion.description}
      </MenuItem>
    );
}

const SearchProduct = (props) => {
    const classes = useStyles();
    const { handleSelect, type, attributes, subType, category } = props;
    const [getProducts, { error, data }] = useLazyQuery(SEARCH_QUERY(category, attributes, subType));
    const [suggestions, setSuggestions] = React.useState([]);

    const handleChange = (item) => {
        handleSelect(item)
    };

    const setAutoCompleteValue = debounce((value) => {
        if (value?.length > 2) {
            getProducts({
                variables: {
                    search: value,
                    type,
                    ...subType && {subType}
                }
            });
        }
    }, 1000); 

    useEffect(() => {
        if (data?.findProduct) {
            setSuggestions(data.findProduct);
        } else if (data?.findLabour) {
            setSuggestions(data.findLabour);
        }
    }, [data]);

    if(error) console.log(error);

    return (
        <Grid item xs={12}>
            <Downshift
                onChange={handleChange}
                onInputValueChange={setAutoCompleteValue}
                itemToString={item => (item ? item.code : '')}
            >
                {({
                    getInputProps,
                    getItemProps,
                    getMenuProps,
                    isOpen,
                    highlightedIndex,
                    selectedItem,
                }) => (
                <div className={classes.container}>
                    {renderInput({
                        fullWidth: true,
                        classes,
                        InputProps: getInputProps({
                            placeholder: 'Search by code or description',
                        }),
                    })}
                    <div {...getMenuProps()}>
                        {isOpen ? (
                            <Paper className={classes.paperA} square>
                                {suggestions.map((suggestion, index) =>
                                    renderSuggestion({
                                        suggestion,
                                        index,
                                        itemProps: getItemProps({ item: suggestion }),
                                        highlightedIndex,
                                        selectedItem,
                                    }),
                                )}
                            </Paper>
                        ) : null
                        }
                    </div>
                </div>
                )}
            </Downshift>
        </Grid>
    );
};

export default SearchProduct;